<template>
  <v-card class="checkout--card">
    <page-loading :show="loading" />
    <div class="wrapper--title u--between mb-4" :class="{ 'mt-4': $mq === 'xs' }">
      <h2>{{ $t('general.paymentInfo') }}</h2>
    </div>
    <div class="wrapper--form">
      <form @submit.prevent="submit">
        <billing-information ref="billingInfo" />
        <!--        <div class="wrapper&#45;&#45;button u&#45;&#45;end mt-3">-->
        <!--          <button type="submit" class="btn btn-primary">-->
        <!--            {{ $t('booking.buy') }}-->
        <!--          </button>-->
        <!--        </div>-->
      </form>
    </div>
  </v-card>
</template>

<script>
const BillingInformation = () => import('@/components/checkout/billing-information.vue');
const PageLoading = () => import('@/components/content-loading/page-loading');
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'checkout-form',
  mixins: [HelperMixin],
  computed: {
    ...mapState({
      loading: (state) => state.checkout.loading,
    }),
  },
  components: {
    BillingInformation,
    // PaymentMethod,
    PageLoading,
  },
  methods: {
    async submit() {
      try {
        const valid = await this.$refs.billingInfo.validate();
        if (valid) {
          const uuid = await this.$store.dispatch(
            'checkout/submit',
            this.$refs.billingInfo.$refs.regionalForm.village.id,
          );
          this.$router.replace({
            path: '/mybooking/detail',
            query: {
              type: 'SELL_TRANSACTION',
              uuid,
            },
          });
        } else {
          this.scrollToErrorSection(this.$el);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>

<style scoped></style>
